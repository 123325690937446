import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertService,
  CallsService,
  AuthenticationService,
  KYBService
} from '../../../_services';
import { config } from '../../../../assets/configuration';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BrandingService } from './../../../branding/branding.service';
import { BrandingSetup } from './../../../branding/branding.model';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

/**
 * Implements password changing directive
 */
@Component({
  selector: 'app-search-engine-screener',
  standalone: true,
  imports: [ CommonModule, FormsModule, NgSelectModule, ReactiveFormsModule ],
  templateUrl: 'searchEngineScreenerSettings.component.html',
  styleUrls: ['./searchEngineScreenerSettings.component.css']
})

export class SearchEngineScreenerSettingsComponent implements OnInit {
  mainColor: any = config.mainColor;
  toggleHiddenEntries = false;
  toggleEntriesId = -1;
  seinclusiveness: any;
  keywords: any = [];
  searchQueries: any = {};
  searchEngineConfigResponse: any = {};
  searchEngineConfig: any = {};
  dataTable: any;
  currentConfig: any = {};
  editModalRef: BsModalRef;
  addModalRef: BsModalRef;
  currentSearchQuery: any = {};
  disableAddSearchQueryBtn: any;
  entityTypes: any = {};
  entityTypesResponse: any = {};
  riskTypes: any = [];

  constructor(
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private kybService: KYBService,
    private modalService: BsModalService,
    private brandingService: BrandingService
  ) {}

  ngOnInit() {
    this.kybService.getSearchEngineScreeningConfig().subscribe(
      data => {
        this.currentConfig = data;
      },
      error => {
      }
    );

    this.kybService.getSearchEngineQueries().subscribe(
      data => {
        this.searchQueries = data;
        this.initTable();
      },
      error => {
      }
    );

    this.kybService.getEntityTypes().subscribe(
      data => {
        this.entityTypesResponse = data;
        this.entityTypes = this.entityTypesResponse.data;
      },
      error => {
      }
    );

    this.kybService.listScreeningRisk().subscribe(
      data => {
        this.riskTypes = data;
      },
      error => {
      }
    );

    this.getBranding();
  }


  public getBranding(): void {
    this.brandingService.brandingSetup.subscribe((setup: BrandingSetup) => {
      if (!setup) {
        return;
      }

      this.mainColor = setup.mainColor;
    });
  }

  initTable() {
    const table: any = $('.table-search-engine');
    this.dataTable = table.DataTable({
      oLanguage: { sEmptyTable: 'No search queries found.' },
      orderCellsTop: true,
      columnDefs: [
        { orderable: false, targets: 0 },
        { orderable: false, targets: 1 },
        { orderable: false, targets: 2 },
        { orderable: false, targets: 3 },
        { orderable: false, targets: 4 },
        { orderable: false, targets: 5 },
        { orderable: false, targets: 6 }
      ],
      order: [],
      paging: false,
      retrieve: true,
      lengthChange: false,
      lengthMenu: [
        [30, 50, 100, -1],
        [30, 50, 100, 'All']
      ],
      dom: `<'top'l<'clear'>>rt<'bottom'<'clear'>>`,
      select: { style: 'os' }
    });

    if (this.searchQueries.length > 0) {
      const empty = document.getElementsByClassName(
        'dataTables_empty'
      ) as HTMLCollectionOf<Element>;
      if (empty.length > 0) {
        empty[0].setAttribute('style', 'display: none;');
      }
    }
  }

  showRemaingInformation(index) {
    this.toggleHiddenEntries = true;
    this.toggleEntriesId = index;
  }

  hideRemaingInformation() {
    this.toggleHiddenEntries = false;
    this.toggleEntriesId = -1;
  }

  edit(editTemplate: TemplateRef<any>, searchQuery: any) {
    this.currentSearchQuery = searchQuery;
    this.currentSearchQuery.screeningRisk = this.currentSearchQuery.screeningRisk.id;

    const affectedEntityTypeIds = [];

    for (let k = 0; k < this.currentSearchQuery.affectedEntityTypes.length; k++) {
      affectedEntityTypeIds.push(this.currentSearchQuery.affectedEntityTypes[k].id);
    }

    this.currentSearchQuery.affectedEntityTypes = affectedEntityTypeIds;

    this.editModalRef = this.modalService.show(editTemplate, {
      backdrop: 'static'
    });

    this.modalService.onHide.subscribe(
      data => {
        this.ngOnInit();
      }
    );
  }

  save() {
    let searchQueryConfig = {};

    searchQueryConfig = {
      id: this.currentSearchQuery.id,
      affectedEntityTypeIds: this.currentSearchQuery.affectedEntityTypes,
      maxHits: this.currentSearchQuery.maxHits,
      queryKeywords: this.currentSearchQuery.queryKeywords,
      searchEngineType: this.currentSearchQuery.searchEngineType,
      screeningRiskId: this.currentSearchQuery.screeningRisk
    };

    this.kybService.saveSearchEngineQuery(searchQueryConfig).subscribe(
      data => {
        this.editModalRef.hide();
        window.scrollTo(0, 0);
        this.alertService.showSuccess('Query edited successfully.');
        this.currentSearchQuery = {};
        this.ngOnInit();
      },
      error => { }
    );
  }

  addNew(addTemplate: TemplateRef<any>) {
    this.disableAddSearchQueryBtn = new UntypedFormGroup({
      searchEngineDropdown: new UntypedFormControl(null, Validators.required),
      riskType: new UntypedFormControl(null, Validators.required),
      maximumHits: new UntypedFormControl(null, Validators.required)
    });
    this.currentSearchQuery = {};
    this.currentSearchQuery.queryKeywords = [];
    this.addModalRef = this.modalService.show(addTemplate, {
      backdrop: 'static'
    });
  }

  add() {
    let searchQueryConfig = {};

    searchQueryConfig = {
      affectedEntityTypeIds: this.currentSearchQuery.affectedEntityTypes,
      maxHits: this.currentSearchQuery.maxHits,
      queryKeywords: this.currentSearchQuery.queryKeywords,
      searchEngineType: this.currentSearchQuery.searchEngineType,
      screeningRiskId: this.currentSearchQuery.screeningRisk
    };

    this.kybService.saveSearchEngineQuery(searchQueryConfig).subscribe(
      data => {
        this.addModalRef.hide();
        window.scrollTo(0, 0);
        this.alertService.showSuccess('Query added successfully.');
        this.currentSearchQuery = {};
        this.ngOnInit();
      },
      error => {}
    );
  }

  delete(id: any) {
    if (window.confirm('Are you sure you want to delete this?')) {
      this.kybService.deleteSearchEngineQuery(id).subscribe(data => {
        window.scrollTo(0, 0);
        this.alertService.showSuccess('Query deleted.');
        this.currentSearchQuery = {};
        this.ngOnInit();
      });
    }
  }

  keywordsChanged(keyword: string) {
    if (keyword.trim() !== '') {
      this.currentSearchQuery.queryKeywords.push(keyword.trim());
      this.currentSearchQuery.keyword = '';
    }
  }

  saveSettings() {
    this.kybService
      .setSearchEngineScreeningConfig(
        this.currentConfig.searchEngineScreeningInclusiveness
      )
      .subscribe(
        data => {
          window.scrollTo(0, 0);
          this.alertService.showSuccess('Settings saved successfully.');
          this.ngOnInit();
        },
        error => {
        }
      );
  }
}
